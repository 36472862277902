<template>
  <div
    class="
      grid grid-cols-1
      md:grid-cols-1
      gap-12
      items-center
      justify-between
      text-left
      mt-12
      mb-12
    "
  >
    <div
      class="nes-container text-white is-rounded bg-brown-dark overflow-ellipsis overflow-hidden"
    >
      PixelDebates is an NFT-powered trading card game themed around presidential debates.
      <br /><br />Players collect and build a deck of tactics cards, combine them with a POTUS
      champion from
      <a class="text-blue-link" href="PixelPotus.com" target="_blank">PixelPotus.com</a>, and engage
      in PvP battles where they can earn
      <a class="text-blue-link" href="pxl.games#pxl" target="_blank">$PXL</a>. <br /><br />You can
      claim 1 <b>free fragment</b> each day and up to five more for a fee in $PXL.
      <br />
      <br />
      <a class="nes-btn is-primary" href="https://play.pixeldebates.com" target="_blank">
        Play PixelDebates TCG (Beta) Now
      </a>
      or
      <router-link v-if="state.userAddress" class="nes-btn is-primary" to="/claim">
        Start Collecting Tactics
      </router-link>
      <a v-else class="nes-btn is-primary" href="#connectWallet"> Connect Your Wallet </a>
    </div>

    <div
      class="
        nes-container
        text-white
        is-rounded
        bg-black
        overflow-ellipsis overflow-hidden
        flex flex-col
        text-center
        items-center
        gap-4
      "
    >
      <img src="https://tournament.pixeldebates.com/images/logo-min.png" />
      Get ready for the most exciting play-to-earn event on Tezos!
      <span class="text-red-500 text-xl">The Prize - $10,000</span>
      Date TBD - The tournament is delayed for now due to market conditions.
      <!-- <a
        class="sm:text-right nes-btn is-primary"
        href="https://tournament.pixeldebates.com"
        target="_blank"
      >
        Learn More
      </a> -->
    </div>

    <div id="connectWallet" class="nes-container is-rounded grid grid-cols-1 md:grid-cols-2">
      <div class="p-2">
        <p>TLDR:</p>
        <p>
          Step 1:
          <a
            v-if="!state.isMobile"
            class="text-blue-link"
            href="https://thanoswallet.com/"
            target="_blank"
            >Create</a
          >
          <a v-if="state.isMobile" class="text-blue-link" href="https://kukai.app/" target="_blank"
            >Create</a
          >
          & then connect your Tezos web wallet.
        </p>
        <p>
          Step 2:
          <a class="text-blue-link" href="https://www.coinbase.com/price/tezos" target="_blank"
            >Acquire some XTZ</a
          >
          to pay for transaction fees.
        </p>
        <p>
          Step 3:
          <a
            class="text-blue-link"
            href="https://quipuswap.com/swap?from=tez&to=KT1F1mn2jbqQCJcsNgYKVAQjvenecNMY2oPK_0"
            target="_blank"
            >Acquire some $PXL.</a
          >
        </p>
        <p>Step 4: Claim, craft and trade TACTICS NFTs.</p>
        <p>
          Step 5:
          <a class="text-blue-link" href="https://www.pxl.games/register" target="_blank"
            >Create a Pixel Profile</a
          >
        </p>
        <p>
          Step 6:
          <a class="text-blue-link" href="https://play.pixeldebates.com" target="_blank"
            >Play PixelDebates</a
          >
        </p>
        <a href="#get-started" class="text-blue-link"><small>more info...</small></a>
      </div>
      <div v-if="state.userAddress" class="flex flex-col items-center justify-center">
        <div
          class="
            border-2 border-dotted border-black
            gap-2
            flex flex-col
            items-center
            justify-center
            p-8
          "
        >
          Connected to
          <AddrIcon
            :address="state.userAddress"
            :alias="state.userAlias"
            :show-disconnect="true"
            :is-pill="true"
            :link-to-collection="true"
          />
          {{ state.convertFromMutez(state.pxlBalance) }} $PXL
          <a
            href="https://quipuswap.com/swap?from=tez&to=KT1F1mn2jbqQCJcsNgYKVAQjvenecNMY2oPK_0"
            target="_blank"
          >
            Need more $PXL?
          </a>
        </div>
      </div>
      <div v-if="!state.userAddress" class="flex flex-col items-center justify-center">
        <div
          v-if="!state.geoLoaded"
          class="
            flex flex-row
            items-center
            justify-center
            text-black
            border-2 border-black border-dashed
            p-2
          "
        >
          <div class="text-lg text-gray-800">Loading...</div>
        </div>
        <div
          v-else-if="!state.canParticipate"
          class="
            flex flex-row
            items-center
            justify-center
            text-black
            border-2 border-black border-dashed
            p-2
          "
        >
          <div class="text-lg text-gray-800">
            Unfortunately, users from your jurisdiction are not allowed to participate.
          </div>
        </div>
        <div
          v-else-if="!state.agreedToDisclaimer"
          class="
            flex flex-row
            items-center
            justify-center
            text-black
            border-2 border-black border-dashed
            p-2
          "
        >
          <span class="p-5">
            <input
              type="checkbox"
              @change="
                () => {
                  state.saveDisclaimer()
                }
              "
            />
          </span>
          <div class="text-lg text-gray-800">
            In order to connect your wallet, please check this box to indicate you have read and
            agree to our
            <a href="/terms" target="_blank" class="text-blue-dark"> terms & conditions. </a>
          </div>
        </div>
        <div class="mt-5 flex flex-col items-center text-center justify-center">
          <button
            class="
              self-center
              text-sm
              uppercase
              py-4
              px-6
              text-white
              bg-blue-dark
              hover:bg-blue-light
              border-2 border-gray-700
              nes-btn
              is-primary
              h-20
            "
            :class="{ 'is-disabled': !state.agreedToDisclaimer }"
            :disabled="!state.agreedToDisclaimer"
            @click="connectWallet"
          >
            Connect Wallet
          </button>
          <a href="/terms" target="_blank" class="text-blue-dark hov:text-blue-light">
            Terms & Conditions
          </a>
        </div>
      </div>
    </div>

    <div class="video-container">
      <iframe
        class="m-auto"
        src="https://www.youtube-nocookie.com/embed/xESaOHPsCdY"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </div>

    <div class="nes-container is-rounded">
      <div class="flex flex-col md:flex-row items-center justify-center px-10 py-10">
        <div class="col-span-2">
          <img src="@/assets/level-up-triangle.png" />
        </div>
        <div class="col-span-4 ml-16">
          <h2 class="text-2xl">Collect and Upgrade</h2>
          <ul class="list-inside list-disc text-sm mt-10">
            <li>
              Collect 3 unique fragments of the same card and pay a fee in $PXL in order to forge
              them into a 1 Star TACTIC.
            </li>
            <li>Craft 15 unique TACTICs to complete your first deck.</li>
            <li>Upgrade your TACTICs up to 3 stars for an in-game damage boost.</li>
            <li>
              <router-link class="text-blue-link" to="/gameplay"
                >Learn more about the trading card game.</router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="flex flex-col items-center justify-center">
        <div class="m-10">
          <img src="@/assets/level-up.png" />
        </div>
      </div>
    </div>

    <div class="nes-container is-rounded flex flex-col items-center justify-center">
      <h2 class="text-2xl">The trading card game is here!</h2>
      <img src="../assets/board.jpg" class="mb-4" />
      <a
        class="sm:text-right nes-btn is-primary"
        href="https://play.pixeldebates.com"
        target="_blank"
      >
        Play PixelDebates (Beta)
      </a>
    </div>

    <div class="flex flex-col items-center justify-center gap-10">
      <h2 id="get-started" class="text-2xl">To get started...</h2>
      <SpeechBubble :img="state.images.nixon" :show="true" :potus="'Nixon'">
        <div class="sm:hidden">
          You need a Tezos wallet - we like the Kukai web wallet - it works on mobile and makes
          interacting with PixelDebates and other apps easy->
          <a class="text-blue-link" href="https://kukai.app/" target="_blank">Kukai</a>
        </div>
        <div class="hidden sm:inline">
          You need a Tezos wallet - we like the Temple web wallet - it's a Chrome extension and
          makes interacting with PixelDebates and other apps easy->
          <a class="text-blue-link" href="https://thanoswallet.com/" target="_blank"
            >Temple Wallet</a
          >
        </div>
      </SpeechBubble>

      <SpeechBubble :img="state.images.clinton" :show="true" :potus="'Clinton'" :is-left="false">
        Once that's installed, follow the steps in the extension to create a shiny new wallet.
        Please backup your wallet seed and password. No one can help you if you lose them and you
        can lose access to your assets forever.
      </SpeechBubble>

      <SpeechBubble :img="state.images.kennedy" :show="true" :potus="'Kennedy'">
        You will need a little bit of XTZ (Tezos) in your wallet to pay for gas fees on the network.
        Head over to your favorite exchange (<a
          class="text-blue-link"
          href="https://www.coinbase.com/price/tezos"
          target="_blank"
          >Coinbase</a
        >,
        <a class="text-blue-link" href="https://www.gemini.com/prices/tezos" target="_blank"
          >Gemini</a
        >,
        <a class="text-blue-link" href="https://www.binance.com/en/trade/XTZ_USDT" target="_blank"
          >Binance</a
        >) and grab a few XTZ, then send it to your new wallet address.
      </SpeechBubble>
      <SpeechBubble :img="state.images.nixon" :show="true" :potus="'Nixon'">
        Swap XTZ for a handful of $PXL using
        <a
          class="text-blue-link"
          href="https://quipuswap.com/swap?from=tez&to=KT1F1mn2jbqQCJcsNgYKVAQjvenecNMY2oPK_0"
          target="_blank"
          >QuipuSwap.</a
        >
        Once you see the new XTZ balance show up in your wallet you are ready to start collecting
        some TACTICs!
      </SpeechBubble>
      <SpeechBubble
        :img="state.images.greatseal"
        :show="true"
        :potus="'Illuminati'"
        :is-left="false"
        class="pb-8"
      >
        Connect the wallet you created and start claiming TACTICs NFT fragments, crafting tactics,
        trading in the marketplace and planning your deck(s).
      </SpeechBubble>
      <SpeechBubble :img="state.images.kennedy" :show="true" :potus="'Kennedy'">
        Create your Pixel Profile and associate your wallet:
        <a class="text-blue-link" href="https://www.pxl.games/register" target="_blank"
          >PXL Games</a
        >
      </SpeechBubble>

      <SpeechBubble :img="state.images.clinton" :show="true" :potus="'Clinton'" :is-left="false">
        Now you are ready to login and
        <a class="text-blue-link" href="https://play.pixeldebates.com" target="_blank"
          >Play PixelDebates!</a
        >
        Start debating in Practice Mode. Once you have a deck of 15 Tactics, you can win $PXL in PvP
        Mode or play to earn in Campaign Mode.
      </SpeechBubble>
    </div>
  </div>

  <div class="nes-container text-white is-rounded bg-brown-dark overflow-ellipsis overflow-hidden">
    Checkout what is happening in the PixelDebates community:
    <br /><br />
    <span v-if="state.userAddress">
      Tokens:
      <a
        :href="`https://better-call.dev/mainnet/${state?.ppMain?.address}/operations`"
        target="_blank"
        class="overflow-ellipsis overflow-hidden text-blue-link"
        >{{ formattedAddr }}</a
      >
      <br /><br />
      Claims:
      <a
        :href="`https://better-call.dev/mainnet/${state?.ppClaimer?.address}/operations`"
        target="_blank"
        class="overflow-ellipsis overflow-hidden text-blue-link"
        >{{ state.formattedContract(state?.ppClaimer?.address) }}</a
      >
      <br /><br />
      Upgrades:
      <a
        :href="`https://better-call.dev/mainnet/${state?.ppCrafter?.address}/operations`"
        target="_blank"
        class="overflow-ellipsis overflow-hidden text-blue-link"
        >{{ state.formattedContract(state?.ppCrafter?.address) }}</a
      >
      <br /><br />
      Marketplace:
      <a
        :href="`https://better-call.dev/mainnet/${state?.ppMarket?.address}/operations`"
        target="_blank"
        class="overflow-ellipsis overflow-hidden text-blue-link"
        >{{ state.formattedContract(state?.ppMarket?.address) }}</a
      >
      <br /><br />
    </span>
    Twitter:<a
      href="https://twitter.com/PixelPotus"
      target="_blank"
      class="overflow-ellipsis overflow-hidden text-blue-link"
      >@PixelPotus</a
    >
    <br /><br />
    Discord:<a
      href="https://discord.gg/CbdbvwtwkM"
      target="_blank"
      class="overflow-ellipsis overflow-hidden text-blue-link"
      >Invitation</a
    >
    <br /><br />
    Telegram:<a
      href="https://t.me/pixelpotus"
      target="_blank"
      class="overflow-ellipsis overflow-hidden text-blue-link"
      >Invitation</a
    >
  </div>
</template>

<script>
import AddrIcon from '../components/AddrIcon.vue'
import SpeechBubble from '../components/SpeechBubble'

export default {
  name: 'Home',
  components: {
    SpeechBubble,
    AddrIcon,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    connectWallet: Function,
  },
  data() {
    return {
      state: this.$root.$data.state,
      // state: {...this.$root.$data.state, userAddress: true},
      btns: [
        {
          label: 'claim',
          styles: 'nes-btn is-error',
          description: 'Snag up to 5 FRAGMENTS',
          click: () => {
            this.$router.push('/claim')
          },
        },
        {
          label: 'upgrade',
          styles: 'nes-btn is-primary',
          description: 'Combine your FRAGMENTS and upgrade your cards for more power.',
          click: () => {
            this.$router.push('/me')
          },
        },
        {
          label: 'trade',
          styles: 'nes-btn is-warning',
          description: 'Exchange TACTICS NFTs with other constituents',
          click: () => {
            this.$router.push('/market')
          },
        },
      ],
    }
  },
  computed: {
    formattedAddr() {
      return (
        this.state.contract.substring(0, 5) +
        '..' +
        this.state.contract.substring(this.state.contract.length - 5)
      )
    },
    recordBreakers() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.state.allTokens
        .sort((a, b) => {
          return b.high - a.high
        })
        .slice(0, 3)
    },
  },
  async mounted() {
    if (this.$route.query?.ref && this.$route.query?.ref.length === 36) {
      localStorage.setItem('debatesReferrer', this.$route.query?.ref)
    }
  },
  methods: {},
}
</script>
